.Control::placeholder{
    font-size:.8rem;
}

.label{
    font-size:.9rem;
    font-weight: 400;
    color:#08283B;
}



.Control{
    font-size:.8rem;
    padding:.7rem;
    border-radius: 25px !important;
}

.oldP {

}


.SelectCon{
    font-size:.7rem;
    padding-left:.7rem !important;
}

.cursor{
    cursor: pointer;
}


.change{
    color:#0F4E71;
    cursor:pointer;
}

.account{
    margin-top: 1.5rem;
    font-size: .8rem;
}

.inputContent{
    margin:auto;
    margin-top:2rem;
    width:50%;
}

.inputs{
    border-color:lightgray !important;
    border-radius: 15px;
    width: 100%;
}

.inputs::placeholder{
    font-size: 13px;
}

.inputs::-webkit-inputs-placeholder { /* WebKit browsers */
    font-size: 13px;
}
.inputs:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: 13px;
}
.inputs::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-size: 13px;
}
.inputs:-ms-inputs-placeholder { /* Internet Explorer 10+ */
    font-size: 13px;
}