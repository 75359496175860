.container {
	width: 5em;
	transition: width ease-in-out 1s;
}

/* .listObject:hover {
	background-color: white;
	color: black;
	height: 2em;
} */
.listObject div {
	width: 100%;
	color: #fff;
	transition: all ease-in-out 0.1s;
}

.listObject div:hover {
	background-color: white;
	color: black;
	height: 2em;
}
.listObject ul div:hover {
	background-color: transparent;
	color: #0e9cec;
	height: 2em;
}

.onCont {
	width: 16em;
	transition: width ease-in-out 1s;

	@media screen and (max-width: 800px) {
		width: 5em;
	}
}

.onCont .leftnavBar {
	opacity: 1;
	display: flex;

	@media screen and (max-width: 800px) {
		display: none;
	}
}

.logo {
	opacity: 1;
}

.logo2 {
	position: absolute;
	opacity: 0;
}

.onCont .logo {
	opacity: 0;
	transition: all ease-in-out 1s;
	display: flex;

	@media screen and (max-width: 800px) {
		opacity: 1;
	}
}

.onCont .logo2 {
	opacity: 1;
	transition: all ease-in-out 1s;
	display: flex;

	@media screen and (max-width: 800px) {
		opacity: 0;
	}
}

.listObject .active div {
	color: #0e9cec !important;
}
.listObject .active div {
	background-color: transparent;
}
.active div {
	background-color: white;
	color: black;
}

.inactive div {
	color: #fff;
	background-color: transparent;
}

.leftnavBar {
	opacity: 0;
	display: none;
	transition: all ease-in-out 0.1s;
	min-width: 5em;
	z-index: 2;
	flex-shrink: 0;
}

.onNav {
	display: flex;
}

.container:hover {
	width: 16em;
}

.container:hover .leftnavBar {
	opacity: 1;
	transition: all ease-in-out 1s;
	display: flex;
}

.container:hover .logo {
	opacity: 0;
	transition: all ease-in-out 0.7s;
	display: flex;
}

.container:hover .logo2 {
	opacity: 1;
	transition: all ease-in-out 0.7s;
	display: flex;
}
