.button {
    padding:.5rem 1rem;
    font-size: .8rem;
    border-radius: .2rem;
    outline: none;
    background-color:#0F4E71;
    border:none;
}


