.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.rs-table-row-expanded {
	padding: 0 !important;
}
.rs-picker-menu {
	z-index: 999999 !important;
}

#sidebarMenu .nav-item:hover,
#sidebarMenu .nav-item:hover > .nav-link {
	background: #ffffff;
	color: var(--bs-primary) !important;
	border-left: 3px solid var(--bs-primary);
}

/* .rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, */
.rs-picker-input .rs-picker-toggle {
	border: 1px solid grey;
	border-radius: 18px !important;
	background-color: none !important;
	font-size: 13px !important; /* adjust the size to your liking */
}

#sidebarMenu .nav-item.active {
	background: #ffffff;
	color: var(--bs-primary) !important;
	border-left: 3px solid var(--bs-primary);
}
#sidebarMenu .nav-item.active a {
	background: #ffffff;
	color: var(--bs-primary) !important;
	border-left: 3px solid var(--bs-primary);
}

html,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
}

.table > :not(caption) > * > * {
	padding: 0.75rem 0.5rem !important;
}

.table-icon .list-group-item {
	cursor: pointer;
}

.table-icon .list-group-item:hover {
	background: var(--bs-primary);
	color: #fff;
}

.cardHover:hover {
	transform: translateY(-10px);
	box-shadow: 5px 2rem 3rem rgb(0 0 0 / 16%) !important;
}

.table-icon .content {
	display: none;
}
.table-icon:hover .content {
	display: block;
	right: 1rem;
	z-index: 9999999 !important;
}
.content:hover {
	display: block;
}

.logout:hover {
}

.rs-picker-input:not(.rs-picker-disabled) .rs-picker-toggle {
	left: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-float infinite 3s ease-in-out;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}
